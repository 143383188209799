<template>
  <v-dialog v-if="isOpen" v-model="isOpen" width="1000">
    <v-card>
      <v-card-title>{{ originalMode?.name }}</v-card-title>

      <v-card-subtitle class="mt-n4 mb-4">{{ formulaId }}</v-card-subtitle>

      <v-card-text>
        <v-alert type="info" class="mb-6">
          To release the changes saved in this dialog you need to rollout the
          <b>"Spotlight changes"</b>
          project!
        </v-alert>

        <v-row>
          <v-col class="d-flex flex-column justify-center">
            <div class="text-button text-primary text-center">Morning</div>

            <v-list class="flex-grow-1">
              <draggable v-model="editedMode.morning">
                <v-list-item
                  v-for="item in editedMode.morning"
                  :key="item"
                  class="text-center"
                  :title="item.toUpperCase()"
                />
              </draggable>
            </v-list>

            <v-btn
              v-if="!editedMode.morning.includes(formulaId)"
              size="small"
              color="primary"
              prepend-icon="mdi-plus"
              text="Add to morning"
              @click="editedMode.morning.push(formulaId)"
            />
            <v-btn
              v-else
              size="small"
              color="primary"
              prepend-icon="mdi-minus"
              text="Remove from morning"
              @click="editedMode.morning = editedMode.morning.filter((m: string) => m !== formulaId)"
            />
          </v-col>

          <v-col class="d-flex flex-column justify-center">
            <div class="text-button text-primary text-center">Daytime</div>

            <v-list class="flex-grow-1">
              <draggable v-model="editedMode.day">
                <v-list-item
                  v-for="item in editedMode.day"
                  :key="item"
                  class="text-center"
                  style="cursor: drag"
                  :title="item.toUpperCase()"
                />
              </draggable>
            </v-list>

            <v-btn
              v-if="!editedMode.day.includes(formulaId)"
              size="small"
              color="primary"
              prepend-icon="mdi-plus"
              text="Add to daytime"
              @click="editedMode.day.push(formulaId)"
            />
            <v-btn
              v-else
              size="small"
              color="primary"
              prepend-icon="mdi-minus"
              text="Remove from daytime"
              @click="editedMode.day = editedMode.day.filter((m: string) => m !== formulaId)"
            />
          </v-col>

          <v-col class="d-flex flex-column justify-center">
            <div class="text-button text-primary text-center">Evening</div>

            <v-list class="flex-grow-1">
              <draggable v-model="editedMode.evening">
                <v-list-item
                  v-for="item in editedMode.evening"
                  :key="item"
                  class="text-center"
                  :title="item.toUpperCase()"
                />
              </draggable>
            </v-list>

            <v-btn
              v-if="!editedMode.evening.includes(formulaId)"
              size="small"
              color="primary"
              prepend-icon="mdi-plus"
              text="Add to evening"
              @click="editedMode.evening.push(formulaId)"
            />
            <v-btn
              v-else
              size="small"
              color="primary"
              prepend-icon="mdi-minus"
              text="Remove from evening"
              @click="editedMode.evening = editedMode.evening.filter((m: string) => m !== formulaId)"
            />
          </v-col>

          <v-col class="d-flex flex-column justify-center">
            <div class="text-button text-primary text-center">Overnight</div>

            <v-list class="flex-grow-1">
              <draggable v-model="editedMode.overnight">
                <v-list-item v-for="item in editedMode.overnight" :key="item" :title="item.toUpperCase()" />
              </draggable>
            </v-list>

            <v-btn
              v-if="!editedMode.overnight.includes(formulaId)"
              size="small"
              color="primary"
              prepend-icon="mdi-plus"
              text="Add to overnight"
              @click="editedMode.overnight.push(formulaId)"
            />
            <v-btn
              v-else
              size="small"
              color="primary"
              prepend-icon="mdi-minus"
              text="Remove from overnight"
              @click="editedMode.overnight = editedMode.overnight.filter((m: string) => m !== formulaId)"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn text="Close" @click="isOpen = false" />

        <v-btn text="Save" color="primary" :loading="isSaving" :disabled="!hasDataChanges" @click="saveChanges()" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { cloneDeep, isEqual } from 'lodash-es'

  import { VueDraggableNext } from 'vue-draggable-next'

  import { Component, Vue, toNative } from 'vue-facing-decorator'

  import { FormulasStore } from '#stores'

  @Component({
    components: {
      draggable: VueDraggableNext,
    },
  })
  class SpotlightMode extends Vue {
    public formulaId = ''

    public isOpen = false
    public isSaving = false

    public editedMode: any = null
    public originalMode: any = null

    protected readonly formulasStore = new FormulasStore()

    public get hasDataChanges() {
      return !isEqual(this.editedMode, this.originalMode)
    }

    public async open(mode: any, formulaId: string) {
      this.originalMode = mode

      this.editedMode = cloneDeep(mode)

      this.formulaId = formulaId

      this.isOpen = true
    }

    public async saveChanges() {
      this.isSaving = true

      await this.formulasStore.updateSpotlightMode(this.editedMode)

      this.isSaving = false
      this.isOpen = false
    }
  }

  export default toNative(SpotlightMode)
</script>
