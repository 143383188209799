<template>
  <v-app>
    <template v-if="user === undefined">
      <OuraSpinner />
    </template>

    <template v-if="user === null">
      <OuraAuth title="Waltari - Content editor" @login="appStore.login()" />
    </template>

    <template v-else-if="user !== undefined">
      <v-navigation-drawer
        v-if="!$route.meta || !$route.meta.panel"
        style="z-index: 1200"
        :permanent="$vuetify.display.mdAndUp"
        :temporary="$vuetify.display.smAndDown"
        :rail="$vuetify.display.smAndDown ? false : miniNav"
        :model-value="$vuetify.display.smAndDown ? navDrawer : true"
      >
        <div class="d-flex flex-column fill-height">
          <v-list nav>
            <v-list-item
              link
              prepend-icon="mdi-menu"
              @click="$vuetify.display.smAndDown ? (navDrawer = !navDrawer) : (miniNav = !miniNav)"
            >
              <v-list-item-title />
            </v-list-item>

            <v-divider />

            <v-list-item link to="/" class="mb-0" title="Home" prepend-icon="mdi-home">
              <v-tooltip activator="parent" :disabled="!miniNav">Home</v-tooltip>
            </v-list-item>

            <v-divider />

            <v-list-item
              link
              to="/rules"
              title="Rule Formulas"
              prepend-icon="mdi-code-json"
              :active="$route.path.startsWith('/evals') || $route.path.startsWith('/rules')"
              :class="
                project &&
                !project?.sections?.content &&
                !$route.path.startsWith('/evals') &&
                !$route.path.startsWith('/rules') &&
                'opacity-50'
              "
            >
              <v-tooltip activator="parent" :disabled="!miniNav">Rule Formulas</v-tooltip>
            </v-list-item>

            <v-list-item
              v-if="isInsightsEditor"
              to="/priorities"
              title="Priorities Sim"
              prepend-icon="mdi-calculator"
              :active="$route.path.startsWith('/priorities')"
              :class="project && !project?.sections?.content && !$route.path.startsWith('/priorities') && 'opacity-50'"
            >
              <v-tooltip activator="parent" :disabled="!miniNav">Priorities Sim</v-tooltip>
            </v-list-item>

            <v-divider v-if="isInsightsEditor || isContentsEditor" />

            <v-list-item
              v-if="isInsightsEditor"
              link
              class="mb-0"
              to="/messaging"
              title="App Insights"
              prepend-icon="mdi-lightbulb-on-outline"
              :active="
                $route.path.startsWith('/messaging') ||
                $route.path.startsWith('/tipthemes') ||
                $route.path.startsWith('/slideshows')
              "
              :class="
                project &&
                !project?.sections?.content &&
                !$route.path.startsWith('/messaging') &&
                !$route.path.startsWith('/tipthemes') &&
                !$route.path.startsWith('/slideshows') &&
                'opacity-50'
              "
            >
              <v-tooltip activator="parent" :disabled="!miniNav">App Insights</v-tooltip>
            </v-list-item>

            <v-list-item
              v-if="isContentsEditor"
              link
              to="/contents"
              title="App Contents"
              prepend-icon="mdi-asterisk"
              :active="$route.path.startsWith('/contents')"
              :class="project && !project?.sections?.content && !$route.path.startsWith('/contents') && 'opacity-50'"
            >
              <v-tooltip activator="parent" :disabled="!miniNav">App Contents</v-tooltip>
            </v-list-item>

            <v-divider />

            <v-list-item
              link
              to="/segments"
              title="User Segments"
              prepend-icon="mdi-account-group-outline"
              :active="$route.path.startsWith('/segments')"
              :class="project && !project?.sections?.features && !$route.path.startsWith('/segments') && 'opacity-50'"
            >
              <v-tooltip activator="parent" :disabled="!miniNav">User Segments</v-tooltip>
            </v-list-item>

            <v-list-item
              link
              class="mb-0"
              to="/features"
              title="Feature Flags"
              prepend-icon="mdi-ab-testing"
              :active="$route.path.startsWith('/features')"
              :class="project && !project?.sections?.features && !$route.path.startsWith('/features') && 'opacity-50'"
            >
              <v-tooltip activator="parent" :disabled="!miniNav">Feature Flags</v-tooltip>
            </v-list-item>

            <v-divider />

            <v-list-item
              v-if="$featureEnabled('appReleaseTool')"
              to="/releases"
              title="App Releases"
              prepend-icon="mdi-rocket-launch"
            >
              <v-tooltip activator="parent" :disabled="!miniNav">App Releases</v-tooltip>
            </v-list-item>

            <v-list-item
              to="/rollouts"
              class="mb-0"
              title="OTA Rollouts"
              prepend-icon="mdi-package-variant-closed"
              :class="project && !project?.sections?.content && !$route.path.startsWith('/rollouts') && 'opacity-50'"
            >
              <v-tooltip activator="parent" :disabled="!miniNav">OTA Rollouts</v-tooltip>
            </v-list-item>

            <template v-if="isInsightsEditor">
              <v-divider />

              <v-list-item
                v-if="isInsightsEditor"
                to="/templates"
                title="Insight Templates"
                prepend-icon="mdi-view-grid-plus-outline"
                :active="$route.path.startsWith('/templates')"
                :class="project && !project?.sections?.content && !$route.path.startsWith('/templates') && 'opacity-50'"
              >
                <v-tooltip activator="parent" :disabled="!miniNav">Insight Templates</v-tooltip>
              </v-list-item>
            </template>
          </v-list>

          <v-spacer />

          <v-list nav>
            <v-list-item title="Feedback" prepend-icon="mdi-bug" @click="$showJiraIssueCollectorDialog()">
              <v-tooltip activator="parent" :disabled="!miniNav">Feedback</v-tooltip>
            </v-list-item>

            <v-list-item to="/logout" title="Logout" prepend-icon="mdi-logout">
              <v-tooltip activator="parent" :disabled="!miniNav">Logout</v-tooltip>
            </v-list-item>
          </v-list>
        </div>
      </v-navigation-drawer>

      <v-app-bar>
        <v-app-bar-title class="d-flex flex-row flex-nowrap flex-grow-1 flex-shrink-1 pt-1" style="min-width: 0">
          <v-btn v-if="$vuetify.display.smAndDown" icon="mdi-menu" @click="navDrawer = !navDrawer" />

          <ProjectsMenu>
            <template v-if="$vuetify.display.mdAndUp">
              <v-app-bar-nav-icon readonly class="mt-n1 mr-2">
                <v-img width="32" height="32" src="/images/logo-white.png" />
              </v-app-bar-nav-icon>

              <span class="flex-shrink-0">Waltari</span>
              <span class="mx-2">-</span>
            </template>
          </ProjectsMenu>
        </v-app-bar-title>

        <v-spacer />

        <!--
          Do not remove yet.
          Waiting for decision if simulator project will be continued
        -->
        <!-- <DatabaseMenu
          v-if="$featureEnabled('insightsSimulator')"
          @open-simulator="appStore.openNavDrawer('simulator')"
        /> -->

        <v-menu offset="22px" width="300" location="bottom end">
          <template #activator="{ props }">
            <div v-bind="props" class="d-flex flex-row text-white align-center" style="cursor: pointer">
              <template v-if="$vuetify.display.mdAndUp">
                <span class="subtitle-1 mx-1">
                  {{ user?.email }}
                </span>
                <v-btn icon="mdi-menu-down" variant="tonal" size="large" density="compact" class="mx-2" />
              </template>

              <v-icon v-else class="mx-4">mdi-account</v-icon>
            </div>
          </template>

          <v-list>
            <v-list-item
              title="Download content"
              append-icon="mdi-cloud-download-outline"
              @click="openDownloadContentDialog()"
            />

            <v-divider />

            <v-list-item title="Rules documentation" append-icon="mdi-api" @click="openDocumentationPages('rules')" />

            <v-list-item
              title="Waltari documentation"
              append-icon="mdi-help-circle-outline"
              @click="openDocumentationPages('waltari')"
            />

            <v-divider />

            <v-list-item title="APP Feature admin role" @click="toggleRoleStatus('appFeatureAdmin')">
              <template #append>
                <div :class="isAppFeatureAdmin ? 'text-success' : 'text-warning'">
                  {{ isAppFeatureAdmin ? 'YES' : 'NO' }}
                </div>
              </template>
            </v-list-item>

            <v-list-item title="APP Release admin role" @click="toggleRoleStatus('appReleaseAdmin')">
              <template #append>
                <div :class="isAppReleaseAdmin ? 'text-success' : 'text-warning'">
                  {{ isAppReleaseAdmin ? 'YES' : 'NO' }}
                </div>
              </template>
            </v-list-item>

            <v-divider />

            <v-list-item title="OTA Config admin role" @click="toggleRoleStatus('otaConfigAdmin')">
              <template #append>
                <div :class="isOTAConfigAdmin ? 'text-success' : 'text-warning'">
                  {{ isOTAConfigAdmin ? 'YES' : 'NO' }}
                </div>
              </template>
            </v-list-item>

            <v-list-item title="OTA Content admin role" @click="toggleRoleStatus('otaContentAdmin')">
              <template #append>
                <div :class="isOTAContentAdmin ? 'text-success' : 'text-warning'">
                  {{ isOTAContentAdmin ? 'YES' : 'NO' }}
                </div>
              </template>
            </v-list-item>

            <v-divider />

            <v-list-item title="CMS Contents editor role" @click="toggleRoleStatus('cmsContentsEditor')">
              <template #append>
                <div :class="isContentsEditor ? 'text-success' : 'text-warning'">
                  {{ isContentsEditor ? 'YES' : 'NO' }}
                </div>
              </template>
            </v-list-item>

            <v-list-item title="CMS Insights editor role" @click="toggleRoleStatus('cmsInsightsEditor')">
              <template #append>
                <div :class="isInsightsEditor ? 'text-success' : 'text-warning'">
                  {{ isInsightsEditor ? 'YES' : 'NO' }}
                </div>
              </template>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu offset="16px" width="300" location="bottom end">
          <template #activator="{ props }">
            <v-btn v-bind="props" icon="mdi-cog" />
          </template>

          <v-list>
            <v-list-item>
              <v-switch
                inset
                color="primary"
                label="Use dark theme"
                class="reversed mt-2 mb-2"
                :model-value="theme?.global?.current?.value?.dark"
                @change="toggleTheme()"
              />
            </v-list-item>
          </v-list>
        </v-menu>

        <OuraApps :env="env" />
      </v-app-bar>

      <!--
        Do not remove yet.
        Waiting for decision if simulator project will be continued
      -->
      <!-- <v-navigation-drawer
        v-model="showSimulator"
        width="420"
        disable-resize-watcher
        style="max-width: calc(100vw - 56px)"
        :temporary="$vuetify.display.mdAndDown"
      >
        <Simulator v-if="showSimulator" @close="appStore.closeNavDrawer()" />
      </v-navigation-drawer> -->

      <v-main class="d-flex flex-column">
        <router-view />
      </v-main>

      <v-footer
        v-if="env !== 'release'"
        :color="sessionNotice ? 'info' : env === 'staging' ? 'purple-lighten-1' : 'black'"
      >
        <div v-if="sessionNotice">
          Session expiring in
          <strong>{{ sessionExpirationInMinutes }}</strong>
          minutes. Please refresh your session by
          <a class="text-primary font-weight-bold" href="#" @click="refreshAuthSession()">logging in</a>
          again.
        </div>

        <template v-else>
          <div>
            <span v-if="$vuetify.display.mdAndUp">Environment:</span>
            <b class="ml-2">{{ env }}</b>
          </div>

          <v-spacer />

          <div>
            <span v-if="$vuetify.display.mdAndUp">Version:</span>
            <b class="ml-2">{{ version }} ({{ revision }})</b>
          </div>
        </template>
      </v-footer>
    </template>

    <DownloadDialog ref="downloadDialog" />

    <v-snackbar location="end bottom" :timeout="-1" :model-value="newConfig || serviceWorker?.needRefresh?.value">
      New update available

      <template #actions>
        <v-btn
          text="Refresh"
          @click="serviceWorker?.needRefresh?.value ? serviceWorker?.updateServiceWorker() : updateRemoteConfig()"
        />
      </template>
    </v-snackbar>
  </v-app>
</template>

<script lang="ts">
  import { startCase } from 'lodash-es'

  import { useRegisterSW } from 'virtual:pwa-register/vue'

  import { ThemeInstance, useTheme } from 'vuetify'

  import { Component, Ref, Setup, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { getAnalytics, setUserId, setUserProperties } from 'firebase/analytics'
  import { fetchAndActivate, getRemoteConfig } from 'firebase/remote-config'

  import { OuraApps, OuraAuth, OuraSpinner } from '@jouzen/outo-toolkit-vuetify'

  import { AppStore, ProjectsStore } from '#stores'

  import { Dialog } from '#types'

  @Component({
    components: {
      OuraAuth,
      OuraApps,
      OuraSpinner,
    },
  })
  class App extends Vue {
    @Setup(() => useTheme())
    public readonly theme: ThemeInstance | undefined

    @Setup(() =>
      useRegisterSW({
        onRegistered(r) {
          if (r) {
            setInterval(
              () => {
                r.update()
              },
              15 * 60 * 1000,
            )
          }
        },
      }),
    )
    public readonly serviceWorker: { needRefresh: { value: boolean }; updateServiceWorker: () => void } | undefined

    public miniNav = true
    public navDrawer = false
    public newConfig = false

    public sessionExpirationInMinutes = 720

    public readonly appStore = new AppStore()
    public readonly projectsStore = new ProjectsStore()

    public env = import.meta.env.VITE_APP_ENV || ''

    public version = process.env.APP_RELEASE_VERSION ?? ''
    public revision = process.env.APP_RELEASE_REVISION ?? ''

    @Ref() private readonly downloadDialog: Dialog | null = null

    private sessionExpirationInterval: number | null = null

    public get user() {
      return this.appStore.user
    }

    public get project() {
      return this.projectsStore.project
    }

    public get workspaceUrl() {
      return `/workspace/${this.user?.uid}`
    }

    /* public get notifications() {
      return this.approvalsStore.notifications || []
    } */

    public get showSimulator() {
      return this.appStore.navDrawer === 'simulator'
    }

    public get sessionNotice() {
      return this.sessionExpirationInMinutes <= 30
    }

    public get isContentsEditor() {
      return this.appStore.isContentsEditor || false
    }

    public get isInsightsEditor() {
      return this.appStore.isInsightsEditor || false
    }

    public get isAppFeatureAdmin() {
      return this.appStore.isAppFeatureAdmin || false
    }

    public get isAppReleaseAdmin() {
      return this.appStore.isAppReleaseAdmin || false
    }

    public get isOTAConfigAdmin() {
      return this.appStore.isOTAConfigAdmin || false
    }

    public get isOTAContentAdmin() {
      return this.appStore.isOTAContentAdmin || false
    }

    public get sessionExpirationTime() {
      return this.appStore.sessionExpirationTime ?? 0
    }

    @Watch('user', { immediate: true })
    protected onUserChanged() {
      if (this.user) {
        setUserId(getAnalytics(), this.user.email)

        setUserProperties(getAnalytics(), {
          contentsEditor: this.isContentsEditor,
          insightsEditor: this.isInsightsEditor,
          appFeatureAdmin: this.isAppFeatureAdmin,
          appReleaseAdmin: this.isAppReleaseAdmin,
          otaContentAdmin: this.isOTAContentAdmin,
        })

        fetchAndActivate(getRemoteConfig())
          .then((activated: boolean) => {
            if (activated) {
              this.newConfig = true
            }
          })
          .catch((err) => {
            console.error('Failed to fetch firebase remote config', err)
          })

        this.projectsStore.subscribeToProjects()

        this.$updateJiraIssueCollectorFields({
          email: this.user?.email ?? '',
          fullname: startCase((this.user?.email ?? '').replace('.', ' ').replace('@ouraring.com', '')),
        })

        /* if (this.isInsightsEditor) {
          this.approvalsStore.subscribeToNotifications()
        } */
        /* } else {
        this.approvalsStore.unsubscribeFromNotifications() */
      }
    }

    @Watch('miniNav', { immediate: true })
    protected onMiniNavChanged() {
      localStorage.OuraMiniNav = this.miniNav
    }

    @Watch('sessionExpirationTime', { immediate: true })
    protected onSessionExpirationTimeChanged() {
      this.setSessionExpiresInMinutes()

      if (this.sessionExpirationInterval) {
        clearInterval(this.sessionExpirationInterval)
      }

      this.sessionExpirationInterval = window.setInterval(() => {
        this.setSessionExpiresInMinutes()
      }, 1000 * 60)
    }

    public async mounted() {
      if (localStorage.OuraMiniNav) {
        this.miniNav = localStorage.OuraMiniNav === 'true'
      }

      this.theme!.global.name.value = localStorage.getItem('OuraDarkMode') === 'true' ? 'dark' : 'light'
    }

    public beforeUnmount() {
      if (this.sessionExpirationInterval) {
        clearInterval(this.sessionExpirationInterval)
      }
    }

    public toggleTheme() {
      this.theme!.global.name.value = this.theme!.global.current.value.dark ? 'light' : 'dark'

      localStorage.setItem('OuraDarkMode', this.theme!.global.current.value.dark.toString())
    }

    public updateRemoteConfig() {
      window.location.reload()
    }

    public refreshAuthSession() {
      this.appStore.logout(true)
    }

    public toggleRoleStatus(role: string) {
      if (this.env !== 'release') {
        this.appStore.toggleRoleOverride(role)
      }
    }

    public openDocumentationPages(help: string) {
      window.open(
        help === 'rules'
          ? 'https://jouzen.github.io/insight-engine/index.html'
          : 'https://ouraring.atlassian.net/wiki/spaces/SW/pages/4066182431/Waltari+Documentation',
        '_blank',
      )
    }

    public openDownloadContentDialog() {
      this.downloadDialog!.open()
    }

    /**
     * Calculates minutes left until the session is expired and sets expiration in minutes
     */
    private setSessionExpiresInMinutes() {
      if (this.sessionExpirationTime) {
        let hourDiff = this.sessionExpirationTime - Date.now()

        this.sessionExpirationInMinutes = Math.max(0, parseInt((hourDiff / 60 / 1000).toFixed(0)))
      }
    }
  }

  export default toNative(App)
</script>
