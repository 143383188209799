// sort-imports-ignore

import { createRouter, createWebHistory } from 'vue-router'

import { getAnalytics, logEvent } from 'firebase/analytics'

import {
  AppStore,
  ContentsStore,
  FeaturesStore,
  InsightsStore,
  ReleasesStore,
  RolloutsStore,
  FormulasStore,
  SlideshowsStore,
  TemplatesStore,
  SegmentsStore,
  TipthemesStore,
  PrefsStore,
} from '#stores'

import Home from '#Home.vue'

import FormulasView from '#views/formulas/FormulasView.vue'

import ContentsView from '#views/contents/ContentsView.vue'

import InsightView from '#views/insights/InsightView.vue'
import InsightsView from '#views/insights/InsightsView.vue'

import SegmentsView from '#views/segments/SegmentsView.vue'

import FeatureView from '#views/features/FeatureView.vue'
import FeaturesView from '#views/features/FeaturesView.vue'

import ReleasesView from '#views/releases/ReleasesView.vue'
import RolloutsView from '#views/rollouts/RolloutsView.vue'

import TemplateView from '#views/templates/TemplateView.vue'
import TemplatesView from '#views/templates/TemplatesView.vue'

import PrioritiesView from '#views/priorities/PrioritiesView.vue'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      component: Home,

      meta: {
        title: 'Home',
      },

      beforeEnter(_to: any, _from: any, next: any) {
        const appStore = new AppStore()

        if (appStore.user) {
          const prefsStore = new PrefsStore()

          prefsStore.subscribeToUserPrefs()
        }

        next()
      },
    },

    {
      path: '/login',
      component: Home,

      meta: {
        title: 'Login',
      },

      beforeEnter(_to: any, _from: any, next: any) {
        const appStore = new AppStore()

        window.setTimeout(() => {
          appStore.login()
        }, 1000)

        next({ path: '/' })
      },
    },
    {
      path: '/logout',
      component: Home,

      meta: {
        title: 'Logout',
      },

      beforeEnter(_to: any, _from: any, next: any) {
        const appStore = new AppStore()

        appStore.logout()

        next({ path: '/' })
      },
    },

    /*
    {
      path: '/workspace/:userId',
      meta: {
        title: 'Workspace',
      },
      component: MyWorkspace,
      props: (route) => ({
        store: 'workspace',
        insightsPath: `/users/${route.params.userId}/insights`,
      }),
      beforeEnter(to: any, _from: any, next: any) {
        const workspaceStore = new WorkspaceStore()

        workspaceStore.subscribeToWorkspace(to.params.userId)

        next()
      },
    },
    {
      path: '/workspace/:userId/:insightId',
      meta: {
        title: 'Edit Workspace insight',
      },
      component: InsightView,
      props: (route) => ({
        store: 'workspace',
        insightPath: `/users/${route.params.userId}/insights/${route.params.insightId}`,
      }),
    },
    {
      path: '/workspace/:userId/:insightId/:tab',
      meta: {
        title: 'Workspace insights',
      },
      component: InsightView,
      props: (route) => ({
        store: 'workspace',
        insightPath: `/users/${route.params.userId}/insights/${route.params.insightId}`,
      }),
    },
    {
      path: '/workspace/:userId/:insightId/messages/:messageId',
      meta: {
        title: 'Edit Workspace insight messages',
      },
      component: InsightView,
      props: (route) => ({
        store: 'workspace',
        insightId: route.params.insightId,
        messageId: route.params.messageId,
        insightPath: `/users/${route.params.userId}/insights/${route.params.insightId}`,
        messagePath: `/users/${route.params.userId}/messages/${route.params.messageId}`,
      }),
    },
    */

    /*
     {
      path: '/review',
      meta: {
        title: 'Workspace review',
      },
      component: ReviewView,
      beforeEnter(to: any, from: any, next: any) {
        const insightsStore = new InsightsStore()
        const approvalsStore = new ApprovalsStore()

        insightsStore.subscribeToInsights()
        approvalsStore.subscribeToReviews()

        next()
      },
    },
    */

    {
      path: '/contents/:contentId?',
      component: ContentsView,

      meta: {
        title: 'Contents',
        routeParams: {
          path: '/contents',
          store: 'session',
        },
      },

      props: (route) => ({
        contentId: route.params.contentId,
        contentsPath: '/contents',
      }),

      beforeEnter(_to: any, _from: any, next: any) {
        const appStore = new AppStore()

        if (appStore.user) {
          const prefsStore = new PrefsStore()
          const contentsStore = new ContentsStore()

          prefsStore.subscribeToUserPrefs()
          contentsStore.subscribeToContents()
        }

        next()
      },
    },

    {
      path: '/rules/:formulaId?',
      component: FormulasView,

      meta: {
        title: 'Insights',
        routeParams: {
          path: '/rules',
          store: 'session',
        },
      },

      props: (route) => ({
        formulaId: route.params.formulaId,
        formulasPath: '/rules',
      }),

      beforeEnter(_to: any, _from: any, next: any) {
        const appStore = new AppStore()

        if (appStore.user) {
          const prefsStore = new PrefsStore()
          const formulasStore = new FormulasStore()
          const insightsStore = new InsightsStore()

          formulasStore.subscribeToRules()
          prefsStore.subscribeToUserPrefs()
          insightsStore.subscribeToInsights()
        }

        next()
      },
    },
    {
      path: '/evals/:formulaId?',
      component: FormulasView,

      meta: {
        title: 'Spotlights',
        routeParams: {
          path: '/evals',
          store: 'session',
        },
      },

      props: (route) => ({
        formulaId: route.params.formulaId,
        formulasPath: '/evals',
      }),

      beforeEnter(_to: any, _from: any, next: any) {
        const appStore = new AppStore()

        if (appStore.user) {
          const prefsStore = new PrefsStore()
          const formulasStore = new FormulasStore()
          const insightsStore = new InsightsStore()

          formulasStore.subscribeToEvals()
          formulasStore.subscribeToModes()

          prefsStore.subscribeToUserPrefs()

          insightsStore.subscribeToInsights()
        }

        next()
      },
    },

    {
      path: '/priorities',
      component: PrioritiesView,

      meta: {
        title: 'Priorities / Simulator',
      },

      props: (route) => ({
        section: route.params.section,
        store: 'insights',
        insightsPath: '/insights',
      }),

      beforeEnter(_to: any, _from: any, next: any) {
        const appStore = new AppStore()

        if (appStore.user) {
          const prefsStore = new PrefsStore()
          const insightsStore = new InsightsStore()

          prefsStore.subscribeToUserPrefs()
          insightsStore.subscribeToInsights()
        }

        next()
      },
    },

    {
      path: '/messaging',
      component: InsightsView,

      meta: {
        title: 'Messaging',
        create: 'Insight',
        routeParams: {
          path: '/messaging',
          store: 'session',
        },
      },

      props: () => ({
        store: 'insights',
        insightsPath: '/insights',
      }),

      beforeEnter(_to: any, _from: any, next: any) {
        const appStore = new AppStore()

        if (appStore.user) {
          const prefsStore = new PrefsStore()
          const formulasStore = new FormulasStore()
          const insightsStore = new InsightsStore()
          const tipthemesStore = new TipthemesStore()

          const templatesStore = new TemplatesStore()

          formulasStore.subscribeToRules()
          prefsStore.subscribeToUserPrefs()
          insightsStore.subscribeToInsights()
          tipthemesStore.subscribeToTipthemes()

          templatesStore.subscribeToTemplates()
        }

        next()
      },
    },
    {
      path: '/messaging/:insightId',
      component: InsightView,

      meta: {
        title: 'Edit Insight',
      },

      props: (route) => ({
        store: 'insights',
        insightId: route.params.insightId,
        insightPath: `/insights/${route.params.insightId}`,
      }),

      beforeEnter(_to: any, _from: any, next: any) {
        const appStore = new AppStore()

        if (appStore.user) {
          const prefsStore = new PrefsStore()
          const formulasStore = new FormulasStore()

          const insightsStore = new InsightsStore()
          const tipthemesStore = new TipthemesStore()

          const templatesStore = new TemplatesStore()

          prefsStore.subscribeToUserPrefs()

          formulasStore.subscribeToEvals()
          formulasStore.subscribeToRules()

          insightsStore.subscribeToInsights()
          tipthemesStore.subscribeToTipthemes()

          templatesStore.subscribeToTemplates()
        }

        next()
      },
    },
    {
      path: '/messaging/:insightId/messages/:messageId',
      component: InsightView,

      meta: {
        title: 'Edit Insight Message',
      },

      props: (route) => ({
        store: 'insights',
        insightId: route.params.insightId,
        messageId: route.params.messageId,
        insightPath: `/insights/${route.params.insightId}`,
        messagePath: `/insights/${route.params.insightId}/messages/${route.params.messageId}`,
      }),

      beforeEnter(_to: any, _from: any, next: any) {
        const appStore = new AppStore()

        if (appStore.user) {
          const prefsStore = new PrefsStore()

          const formulasStore = new FormulasStore()

          const insightsStore = new InsightsStore()

          const templatesStore = new TemplatesStore()

          prefsStore.subscribeToUserPrefs()

          formulasStore.subscribeToEvals()
          formulasStore.subscribeToRules()

          insightsStore.subscribeToInsights()

          templatesStore.subscribeToTemplates()
        }

        next()
      },
    },

    {
      path: '/tipthemes',
      component: InsightsView,

      meta: {
        title: 'Tip Themes',
        create: 'Tip Theme',
        routeParams: {
          path: '/tipthemes',
          store: 'session',
        },
      },

      props: () => ({
        store: 'tipthemes',
        insightsPath: '/tipthemes',
      }),

      beforeEnter(_to: any, _from: any, next: any) {
        const appStore = new AppStore()

        if (appStore.user) {
          const prefsStore = new PrefsStore()

          const tipthemesStore = new TipthemesStore()

          const templatesStore = new TemplatesStore()

          prefsStore.subscribeToUserPrefs()

          tipthemesStore.subscribeToTipthemes()

          templatesStore.subscribeToTemplates()
        }

        next()
      },
    },
    {
      path: '/tipthemes/:insightId',
      component: InsightView,

      meta: {
        title: 'Edit Tip Theme',
      },

      props: (route) => ({
        store: 'tipthemes',
        insightId: route.params.insightId,
        insightPath: `/tipthemes/${route.params.insightId}`,
      }),

      beforeEnter(_to: any, _from: any, next: any) {
        const appStore = new AppStore()

        if (appStore.user) {
          const prefsStore = new PrefsStore()
          const insightsStore = new InsightsStore()
          const tipthemesStore = new TipthemesStore()

          const templatesStore = new TemplatesStore()

          prefsStore.subscribeToUserPrefs()
          insightsStore.subscribeToInsights()
          tipthemesStore.subscribeToTipthemes()

          templatesStore.subscribeToTemplates()
        }

        next()
      },
    },
    {
      path: '/tipthemes/:insightId/messages/:messageId',
      component: InsightView,

      meta: {
        title: 'Edit Tip Theme Message',
      },

      props: (route) => ({
        store: 'tipthemes',
        insightId: route.params.insightId,
        messageId: route.params.messageId,
        insightPath: `/tipthemes/${route.params.insightId}`,
        messagePath: `/tipthemes/${route.params.insightId}/messages/${route.params.messageId}`,
      }),

      beforeEnter(_to: any, _from: any, next: any) {
        const appStore = new AppStore()

        if (appStore.user) {
          const prefsStore = new PrefsStore()

          const tipthemesStore = new TipthemesStore()

          const templatesStore = new TemplatesStore()

          prefsStore.subscribeToUserPrefs()

          tipthemesStore.subscribeToTipthemes()

          templatesStore.subscribeToTemplates()
        }

        next()
      },
    },

    {
      path: '/slideshows',
      component: InsightsView,

      meta: {
        title: 'Slide Shows',
        create: 'Slide Show',
        routeParams: {
          path: '/slideshows',
          store: 'session',
        },
      },

      props: () => ({
        store: 'slideshows',
        insightsPath: '/slideshows',
      }),

      beforeEnter(_to: any, _from: any, next: any) {
        const appStore = new AppStore()

        if (appStore.user) {
          const prefsStore = new PrefsStore()

          const templatesStore = new TemplatesStore()

          const slideshowsStore = new SlideshowsStore()

          prefsStore.subscribeToUserPrefs()

          templatesStore.subscribeToTemplates()

          slideshowsStore.subscribeToSlideshows()
        }

        next()
      },
    },
    {
      path: '/slideshows/:insightId',
      component: InsightView,

      meta: {
        title: 'Edit Slide Show',
      },

      props: (route) => ({
        store: 'slideshows',
        insightId: route.params.insightId,
        insightPath: `/slideshows/${route.params.insightId}`,
      }),

      beforeEnter(_to: any, _from: any, next: any) {
        const appStore = new AppStore()

        if (appStore.user) {
          const prefsStore = new PrefsStore()

          const templatesStore = new TemplatesStore()

          const slideshowsStore = new SlideshowsStore()

          prefsStore.subscribeToUserPrefs()

          templatesStore.subscribeToTemplates()

          slideshowsStore.subscribeToSlideshows()
        }

        next()
      },
    },
    {
      path: '/slideshows/:insightId/messages/:messageId',
      component: InsightView,

      meta: {
        title: 'Edit Slide Show Slide',
      },

      props: (route) => ({
        store: 'slideshows',
        insightId: route.params.insightId,
        messageId: route.params.messageId,
        insightPath: `/slideshows/${route.params.insightId}`,
        messagePath: `/slideshows/${route.params.insightId}/messages/${route.params.messageId}`,
      }),

      beforeEnter(_to: any, _from: any, next: any) {
        const appStore = new AppStore()

        if (appStore.user) {
          const prefsStore = new PrefsStore()

          const templatesStore = new TemplatesStore()

          const slideshowsStore = new SlideshowsStore()

          prefsStore.subscribeToUserPrefs()

          templatesStore.subscribeToTemplates()

          slideshowsStore.subscribeToSlideshows()
        }

        next()
      },
    },

    {
      path: '/segments/:segmentId?',
      component: SegmentsView,

      meta: {
        title: 'Segments',
        routeParams: {
          path: '/segments',
          store: 'session',
        },
      },

      props: (route) => ({
        segmentId: route.params.segmentId,
      }),

      beforeEnter(_to: any, _from: any, next: any) {
        const appStore = new AppStore()

        if (appStore.user) {
          const prefsStore = new PrefsStore()
          const featuresStore = new FeaturesStore()
          const segmentsStore = new SegmentsStore()

          const releasesStore = new ReleasesStore()

          prefsStore.subscribeToUserPrefs()
          featuresStore.subscribeToFeatures()
          segmentsStore.subscribeToSegments()

          releasesStore.subscribeToReleases()
        }

        next()
      },
    },

    {
      path: '/features/:projectId',
      redirect: () => `/features`,
    },
    {
      path: '/features/:projectId?/:featureId?',
      component: FeaturesView,

      meta: {
        title: 'Features',
        routeParams: {
          path: '/features',
          store: 'session',
        },
      },

      props: (route) => ({
        projectId: route.params.projectId,
        featureId: route.params.featureId,
      }),

      beforeEnter(_to: any, _from: any, next: any) {
        const appStore = new AppStore()

        if (appStore.user) {
          const prefsStore = new PrefsStore()
          const featuresStore = new FeaturesStore()

          prefsStore.subscribeToUserPrefs()
          featuresStore.subscribeToFeatures()
        }

        next()
      },
    },
    {
      path: '/features/:projectId/:featureId/rollouts',
      redirect: (to) => `/features/${to.params.projectId}/${to.params.featureId}/rollouts/sandbox`,
    },
    {
      path: '/features/:projectId/:featureId/rollouts/:rolloutView',
      component: FeatureView,

      meta: {
        title: 'Feature',
      },

      props: (route) => ({
        projectId: route.params.projectId,
        featureId: route.params.featureId,
        rolloutView: route.params.rolloutView,
        featurePath: `/features/${route.params.featureId}`,
      }),

      beforeEnter(to: any, _from: any, next: any) {
        const appStore = new AppStore()

        if (appStore.user) {
          const prefsStore = new PrefsStore()

          const featuresStore = new FeaturesStore()
          const segmentsStore = new SegmentsStore()

          const releasesStore = new ReleasesStore()

          prefsStore.subscribeToUserPrefs()

          featuresStore.subscribeToFeatures()
          segmentsStore.subscribeToSegments()

          releasesStore.subscribeToReleases()

          logEvent(getAnalytics(), 'rollouts_view' as string, {
            feature_id: to.params.featureId,
            project_id: to.params.projectId,
            rollout_view: to.params.rolloutView,
          })
        }

        next()
      },
    },
    {
      path: '/features/:projectId/:featureId/rollouts/advanced/:rolloutId?/:rolloutIdx?',
      component: FeatureView,

      meta: {
        title: 'Feature',
      },

      props: (route) => ({
        projectId: route.params.projectId,
        featureId: route.params.featureId,
        rolloutId: route.params.rolloutId,
        rolloutIdx: route.params.rolloutIdx,
        featurePath: `/features/${route.params.featureId}`,
      }),

      beforeEnter(_to: any, _from: any, next: any) {
        const appStore = new AppStore()

        if (appStore.user) {
          const prefsStore = new PrefsStore()

          const featuresStore = new FeaturesStore()
          const segmentsStore = new SegmentsStore()

          const releasesStore = new ReleasesStore()

          prefsStore.subscribeToUserPrefs()

          featuresStore.subscribeToFeatures()
          segmentsStore.subscribeToSegments()

          releasesStore.subscribeToReleases()
        }

        next()
      },
    },

    {
      path: '/releases',
      component: ReleasesView,

      meta: {
        title: 'Releases',
      },

      beforeEnter(_to: any, _from: any, next: any) {
        const appStore = new AppStore()

        if (appStore.user) {
          const prefsStore = new PrefsStore()
          const releasesStore = new ReleasesStore()

          prefsStore.subscribeToUserPrefs()
          releasesStore.subscribeToReleases()
        }

        next()
      },
    },

    {
      path: '/rollouts',
      redirect: () => `/rollouts/content`,
    },
    {
      path: '/rollouts/:packageType',
      component: RolloutsView,

      meta: {
        title: 'Rollouts',
      },

      props: (route) => ({
        packageType: route.params.packageType,
      }),

      beforeEnter(_to: any, _from: any, next: any) {
        const appStore = new AppStore()

        if (appStore.user) {
          const prefsStore = new PrefsStore()
          const rolloutsStore = new RolloutsStore()

          prefsStore.subscribeToUserPrefs()
          rolloutsStore.subscribeToRollouts()
        }

        next()
      },
    },

    {
      path: '/templates',
      component: TemplatesView,

      meta: {
        title: 'Templates',
        routeParams: {
          path: '/templates',
          store: 'session',
        },
      },

      props: () => ({
        templatesPath: `/templates`,
      }),

      beforeEnter(_to: any, _from: any, next: any) {
        const appStore = new AppStore()

        if (appStore.user) {
          const prefsStore = new PrefsStore()
          const templatesStore = new TemplatesStore()

          prefsStore.subscribeToUserPrefs()
          templatesStore.subscribeToTemplates()
        }

        next()
      },
    },
    {
      path: '/templates/:templateId',
      component: TemplateView,

      meta: {
        title: 'Message templates',
      },

      props: (route) => ({
        templateId: route.params.templateId,
        templatePath: `/templates/${route.params.templateId}`,
        templatesPath: `/templates`,
      }),

      beforeEnter(_to: any, _from: any, next: any) {
        const appStore = new AppStore()

        if (appStore.user) {
          const prefsStore = new PrefsStore()
          const templatesStore = new TemplatesStore()

          prefsStore.subscribeToUserPrefs()
          templatesStore.subscribeToTemplates()
        }

        next()
      },
    },

    {
      path: '/:pathMatch(.*)*',
      redirect: () => `/`,
    },
  ],
})

// Let's set the path to store so we can manage user access there
router.beforeEach(async (to, from, next) => {
  const appStore = new AppStore()

  appStore.currentRoute = to.path
  appStore.previousRoute = from.path

  if (to.path.split('/')[1] !== from.path.split('/')[1]) {
    appStore.closeNavDrawer()
  }

  // Wait for the auth state so beforeEnter can be used to load data

  await appStore.auth()

  next()
})

router.afterEach((to) => {
  logEvent(getAnalytics(), 'page_view' as string, { page_title: to.meta?.title, page_path: to.path })
})

export default router
