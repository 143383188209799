<template>
  <v-navigation-drawer
    disable-resize-watcher
    style="right: 0"
    order="0"
    width="650"
    location="end"
    :model-value="isOpen"
  >
    <div class="d-flex flex-column fill-height pr-3">
      <v-row class="flex-grow-0 py-4 px-8">
        <v-col class="flex-grow-1">
          <div class="text-h5 font-weight-light">Daphne</div>
          <div class="text-subtitle-2 font-weight-light text-grey-darken-2">Discussions are ephemeral</div>
        </v-col>

        <v-col class="flex-grow-0">
          <v-btn
            :color="isInsightsEditor ? 'primary' : 'secondary'"
            class="mt-n2 mr-n8"
            icon="mdi-cog"
            variant="text"
            @click="openSettingsDialog()"
          />
        </v-col>

        <v-col class="flex-grow-0">
          <v-btn class="mt-n2 mr-n8" icon="mdi-close" variant="text" rounded="100%" @click="close()" />
        </v-col>
      </v-row>

      <v-divider />

      <div class="flex-grow-1 flex-shrink-1" style="overflow: auto">
        <DaphneThread
          class="mt-n4"
          :insight="insight"
          :insight-path="insightPath"
          :message-order="messageOrder"
          :current-message="currentMessage"
        />
      </div>
    </div>
  </v-navigation-drawer>
  <DaphneSettingsDialog ref="daphneSettingsDialog" />
</template>

<script lang="ts">
  import { Component, Emit, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { AppStore } from '#stores'

  import { Dialog } from '#types'

  @Component({})
  class DaphnePanel extends Vue {
    @Prop() public open!: boolean
    @Prop() public insight!: any
    @Prop() public insightPath!: string
    @Prop() public messageOrder!: number | null
    @Prop() public currentMessage!: any | null

    @Emit('close')
    public close() {
      if (this.appStore.navDrawer === 'daphne') {
        this.appStore.closeNavDrawer()
      }

      return
    }

    declare public $refs: {
      daphneSettingsDialog: Dialog
    }

    private readonly appStore = new AppStore()

    public get isOpen() {
      return this.open || this.appStore.navDrawer === 'daphne'
    }

    public get isInsightsEditor() {
      return this.appStore.isInsightsEditor
    }

    public async openSettingsDialog() {
      this.$refs.daphneSettingsDialog.open()
    }
  }

  export default toNative(DaphnePanel)
</script>
