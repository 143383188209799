<template>
  <v-app-bar>
    <v-app-bar-title>
      <v-menu v-model="viewMenu" offset="18">
        <template #activator="{ props }">
          <span v-bind="props" style="cursor: pointer">
            Rule Formulas / {{ $route.meta && $route.meta.title }} ({{ formulasList.length }})
          </span>

          <v-icon>mdi-triangle-small-down</v-icon>
        </template>

        <v-list>
          <v-list-item
            to="/rules"
            title="Insights"
            prepend-icon="mdi-lightbulb-on-outline"
            :active="inRules"
            :style="inRules && 'pointer-events: none'"
          />
          <v-list-item
            to="/evals"
            title="Spotlights"
            prepend-icon="mdi-lightbulb-spot"
            :active="inEvals"
            :style="inEvals && 'pointer-events: none'"
          />
        </v-list>
      </v-menu>
    </v-app-bar-title>

    <v-spacer />

    <v-text-field
      v-model="routeParams.filterText"
      clearable
      class="mr-2"
      style="max-width: 500px"
      prepend-icon="mdi-magnify"
      :placeholder="'Type to filter formulas...'"
      @click:clear="routeParams.filterText = ''"
    />

    <v-menu v-model="filterMenu" offset="8" min-width="230" max-height="500" :close-on-content-click="false">
      <template #activator="{ props }">
        <v-btn
          v-bind="props"
          icon="mdi-filter-variant-plus"
          :active="!!routeParams.filterType || !!routeParams.filterState"
          :color="props.value ? 'primary' : ''"
        />
      </template>

      <v-list>
        <v-list-subheader>Select additional filter</v-list-subheader>

        <v-menu open-on-hover z-index="1" max-height="600" location="start" :close-on-content-click="true">
          <template #activator="{ props }">
            <v-list-item v-bind="props" title="Formula type" prepend-icon="mdi-menu-left" />
          </template>

          <v-list>
            <v-list-item
              v-for="category in formulaCategories"
              :key="category.value"
              dense
              @click="routeParams.filterType = category.value"
            >
              <template #prepend>
                <div
                  :class="'bg-' + category.color + '-lighten-3 mr-4'"
                  style="border-radius: 50%; min-width: 24px; max-width: 24px; min-height: 24px"
                />
              </template>

              <v-list-item-title>{{ category.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu open-on-hover z-index="1" max-height="600" location="start" :close-on-content-click="true">
          <template #activator="{ props }">
            <v-list-item v-bind="props" title="Formula state" prepend-icon="mdi-menu-left" />
          </template>

          <v-list>
            <v-list-item
              title="Unpublished formulas"
              prepend-icon="mdi-package-variant"
              @click="routeParams.filterState = 'unpublished'"
            />
          </v-list>
        </v-menu>

        <v-divider />

        <v-list-item
          prepend-icon="mdi-close-circle-outline"
          title="Clear filter"
          :disabled="!routeParams.filterType && !routeParams.filterState"
          @click="((routeParams.filterType = ''), (routeParams.filterState = ''), (filterMenu = false))"
        />
      </v-list>
    </v-menu>

    <v-menu offset="8">
      <template #activator="{ props }">
        <v-btn
          v-bind="props"
          :disabled="inEvals"
          :color="props.value ? 'primary' : ''"
          :icon="routeParams.sortOrder === 'alpha' ? 'mdi-sort-alphabetical-ascending' : 'mdi-sort-numeric-descending'"
        />
      </template>

      <v-list>
        <v-list-subheader>Sort order for the list</v-list-subheader>

        <v-list-item
          title="Category and title (asc)"
          prepend-icon="mdi-sort-alphabetical-ascending"
          :disabled="routeParams.sortOrder === 'alpha'"
          @click="routeParams.sortOrder = 'alpha'"
        />

        <v-list-item
          title="Condition count / length (desc)"
          prepend-icon="mdi-sort-numeric-descending"
          :disabled="routeParams.sortOrder === 'count'"
          @click="routeParams.sortOrder = 'count'"
        />
      </v-list>
    </v-menu>
  </v-app-bar>

  <v-container class="d-flex flex-column flex-grow-1 flex-shrink-1">
    <v-row class="flex-grow-0">
      <v-col cols="12" md="9">
        <div v-if="inRules" class="text-h5 font-weight-light">Insight rules define when insights are shown</div>
        <div v-else class="text-h5 font-weight-light">Spotlight evaluations define which insights are highlighted</div>

        <div class="text-subtitle-2 text-medium-emphasis font-weight-light">
          <template v-if="isInsightsEditor">
            Your insights editor rights allow you to view and edit all formulas
          </template>
          <template v-else>You can only view formulas, apply for editor rights in the IT portal</template>
        </div>
      </v-col>

      <v-col md="3" cols="12" class="text-right">
        <v-btn
          text="Create formula"
          color="primary"
          :disabled="!isInsightsEditor || !!(activeProject && !activeProject?.sections?.content)"
          @click="createFormula()"
        />
      </v-col>
    </v-row>

    <v-row v-if="isLoading" align="center" class="flex-grow-1">
      <v-col class="text-center">
        <v-progress-circular class="mt-n12" size="96" color="primary" indeterminate />
      </v-col>
    </v-row>

    <v-row v-else-if="activeProject && !activeProject?.sections?.content">
      <v-col class="d-flex justify-center" cols="12">
        <v-divider />

        <v-alert rounded type="info" class="pa-8 opacity-80" position="absolute" width="50%" style="top: 45%">
          Selected project does not use rule formulas, you can select another project from the top bar or unselect the
          current project to see all the existing rule formulas.
        </v-alert>
      </v-col>
    </v-row>

    <v-row v-else class="d-flex flex-grow-1 flex-shrink-1">
      <v-col cols="12" md="4">
        <span v-if="filteredAndSortedFormulas.length === 0" class="font-weight-light">
          No formulas match the selected filters/project
        </span>
        <div
          id="formulas-list"
          style="overflow: auto; padding-right: 8px; margin-bottom: -30px; max-height: calc(100vh - 365px)"
        >
          <div
            v-for="(r, i) in filteredAndSortedFormulas"
            :id="'item-' + i"
            :key="r.id"
            ref="formulaItemRef"
            style="cursor: pointer"
            class="text-truncate mb-3 px-4 py-2"
            :class="
              'bg-' +
              formulaCategories.find((c: any) => c.value === r.category)?.color +
              (editedFormula?.id === r.id ? '-lighten-1 elevation-3' : '-lighten-4')
            "
            @click="selectFormula(r)"
          >
            {{ r.title || 'New unnamed formula' }}
          </div>
        </div>
      </v-col>

      <v-col cols="12" md="8">
        <div
          style="
            overflow: auto;
            padding-right: 8px;
            margin-right: -8px;
            margin-bottom: -30px;
            max-height: calc(100vh - 365px);
          "
        >
          <CreateFormula
            v-if="!!editedFormula"
            :formula="editedFormula"
            :formulas-path="formulasPath"
            @formula-insights="formulaInsights = $event"
            @update-save-changes="enableSaveChanges = $event"
          />
        </div>
      </v-col>
    </v-row>
  </v-container>

  <SaveChanges
    v-if="!!editedFormula && (!activeProject || activeProject?.sections?.content)"
    :error="
      !editedFormula.createdAt
        ? ''
        : editedFormula.project
          ? ''
          : 'Formula is not assigned to any project, please assign to a project to be able to save changes'
    "
    action="duplicate"
    :author="originalFormula?.author"
    :edited="originalFormula?.updatedAt?.toDate()"
    :loading="isLoading"
    :current="editedFormula"
    :original="originalFormula"
    :deletable="allowDelete"
    :releasable="hasUnpublishedData"
    :data-source="editedFormula"
    :disabled="
      hasDuplicateTitle ||
      !enableSaveChanges ||
      !isInsightsEditor ||
      !editedFormula.title ||
      !editedFormula.project ||
      !editedFormula.description ||
      (inEvals && !editedFormula.order)
    "
    @save="openCommitDialog()"
    @release="openReviewDialog()"
    @confirm-delete="deleteFormula()"
    @execute="duplicateFormula()"
    @open-history-dialog="openHistoryDialog()"
  />

  <CommitDialog ref="commitDialogRef" @created="$router.push(`/${rootPath}/${$event.id}`)" />

  <HistoryDialog ref="historyDialogRef" @publish="publishFormula()" />
</template>

<script lang="ts">
  import slug from 'slug'

  import { orderBy } from 'lodash-es'

  import { useGoTo } from 'vuetify'

  import { Component, Prop, Ref, Setup, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { RouteParams } from '@jouzen/outo-toolkit-vuetify'

  import { insightFormulaCategories, spotlightFormulaCategories } from '#views/formulas/constants'

  import { createDefaultFormula } from '#views/formulas/utilities'

  import { AppStore, FormulasStore, InsightsStore, ProjectsStore } from '#stores'

  import { Author, Dialog, Formula } from '#types'

  @Component({})
  class FormulasView extends mixins(RouteParams) {
    @Prop() public formulaId!: string

    @Prop() public formulasPath!: string

    @Setup(() => useGoTo())
    public goTo!: ReturnType<typeof useGoTo>

    public routeParams = {
      sortOrder: 'alpha',
      filterText: '',
      filterType: '',
      filterState: '',
    }

    public viewMenu = false
    public filterMenu = false
    public commitDialog = false
    public enableSaveChanges = false

    public formulaInsights: any[] = []

    public author: Author | null = null
    public edited: string | undefined = ''

    public editedFormula: Formula | null = null
    public originalFormula: Formula | null = null

    protected readonly appStore = new AppStore()

    protected readonly formulasStore = new FormulasStore()
    protected readonly insightsStore = new InsightsStore()
    protected readonly projectsStore = new ProjectsStore()

    @Ref() protected readonly commitDialogRef: Dialog | null = null
    @Ref() protected readonly historyDialogRef: Dialog | null = null

    @Ref() protected readonly formulaItemRef: HTMLElement[] | null = null

    public get inEvals() {
      return this.appStore.inEvals
    }

    public get inRules() {
      return this.appStore.inRules
    }

    public get rootPath() {
      return this.appStore.route[1]
    }

    public get isLoading() {
      return this.formulasStore.loading
    }

    public get allowDelete() {
      return !this.formulaInsights.length
    }

    public get formulasList() {
      return this.formulasStore.formulas
    }

    public get insightsList() {
      return this.insightsStore.allInsights
    }

    public get activeProject() {
      return this.projectsStore.project
    }

    public get isInsightsEditor() {
      return this.appStore.isInsightsEditor
    }

    public get formulaCategories() {
      return this.inRules ? insightFormulaCategories : spotlightFormulaCategories
    }

    public get hasDuplicateTitle() {
      return this.editedFormula?.createdAt
        ? false
        : this.formulasList.some((r) => r.title.toLowerCase() === this.editedFormula?.title?.toLowerCase())
    }

    public get hasUnpublishedData() {
      return (
        this.originalFormula &&
        (!this.originalFormula.releasedAt ||
          (this.originalFormula.updatedAt &&
            this.originalFormula.releasedAt?.toDate() < this.originalFormula.updatedAt?.toDate()))
      )
    }

    public get filteredAndSortedFormulas() {
      const formulas = this.formulasList.filter(
        (f) =>
          (!this.routeParams.filterText ||
            f.title.toLowerCase().includes(this.routeParams.filterText.toLowerCase()) ||
            f.condition.includes(this.routeParams.filterText)) &&
          (!this.routeParams.filterType || f.category === this.routeParams.filterType) &&
          (!this.routeParams.filterState || f.releasedAt?.toDate() < f.updatedAt?.toDate()),
      )

      const sortedFormulas = this.inEvals
        ? orderBy(formulas, [(o) => o.order, (o) => o.title], ['asc', 'asc'])
        : this.routeParams.sortOrder === 'alpha'
          ? orderBy(
              formulas,
              [(o) => this.formulaCategories.findIndex((c: any) => c.value === o.category), (o) => o.title],
              ['asc', 'asc'],
            )
          : orderBy(formulas, [(o) => o.condition.split('&&').length, (o) => o.condition.length], ['desc', 'desc'])

      return this.editedFormula && !this.editedFormula.createdAt
        ? [this.editedFormula].concat(sortedFormulas)
        : sortedFormulas
    }

    @Watch('formulaId', { immediate: true })
    protected formulaIdChanged() {
      this.formulasListChanged()
    }

    @Watch('formulasList', { immediate: true })
    protected formulasListChanged() {
      this.editedFormula = null

      for (const rule of this.formulasList) {
        if (this.formulaId && rule.id == this.formulaId) {
          this.editedFormula = { ...rule }
        }
      }

      this.originalFormula = this.formulasList.find((r) => r.id === this.formulaId)

      if (!this.editedFormula && this.filteredAndSortedFormulas.length) {
        this.$router.replace(`/${this.rootPath}/${this.filteredAndSortedFormulas[0].id}`)
      }
    }

    @Watch('editedFormula', { immediate: true })
    protected async editedFormulaChanged() {
      if (this.editedFormula) {
        this.filteredAndSortedFormulasChanged()
      }
    }

    @Watch('filteredAndSortedFormulas', { immediate: true })
    protected async filteredAndSortedFormulasChanged() {
      const index = this.filteredAndSortedFormulas.findIndex((r) => r.id === this.editedFormula?.id)

      if (index > -1 && this.formulaItemRef && this.formulaItemRef[index]) {
        window.setTimeout(() => this.goTo('#item-' + index, { container: '#formulas-list' }), 10)
      }
    }

    public selectFormula(formula: any) {
      this.editedFormula = formula ? { ...formula } : null

      if (formula?.id) {
        this.$router.push(`/${this.rootPath}/${formula.id}`)
      }
    }

    public createFormula() {
      if (!this.editedFormula || this.editedFormula.createdAt) {
        this.originalFormula = null

        this.editedFormula = createDefaultFormula(this.activeProject?.id)

        if (this.inEvals) {
          this.editedFormula!.type = 'change'
          this.editedFormula!.order = this.formulasList.length + 1
          this.editedFormula!.category = 'sleep'
        } else {
          this.editedFormula!.category = 'resting_sleep'
        }
      }
    }

    public async publishFormula() {
      await this.formulasStore.publishFormula(this.originalFormula)

      if (this.inRules) {
        for (const i of this.formulaInsights) {
          await this.insightsStore.updateCondition(i)
        }
      }
    }

    public deleteFormula() {
      if (this.editedFormula?.id) {
        this.$confirm('Are you sure you want to delete this formula?', this.editedFormula.title).then((confirmed) => {
          if (confirmed) {
            const index = this.filteredAndSortedFormulas.findIndex((r) => r.id === this.editedFormula!.id)

            this.formulasStore.deleteFormula(this.editedFormula!.id)

            this.formulasStore.unpublishFormula(this.editedFormula!.id)

            this.selectFormula(
              this.filteredAndSortedFormulas[index] || this.filteredAndSortedFormulas[index - 1] || null,
            )
          }
        })
      }
    }

    public duplicateFormula() {
      if (this.inRules) {
        this.editedFormula = {
          ...createDefaultFormula(),
          title: `${this.editedFormula!.title} (Copy)`,
          project: this.editedFormula!.project,
          condition: this.editedFormula!.condition,
          description: this.editedFormula!.description,
        }
      } else {
        this.editedFormula = {
          ...createDefaultFormula(),
          type: this.editedFormula!.type,
          title: `${this.editedFormula!.title} (Copy)`,
          project: this.editedFormula!.project,
          category: this.editedFormula!.category,
          condition: this.editedFormula!.condition,
          description: this.editedFormula!.description,
        }

        this.editedFormula!.order = this.formulasList.length + 1
      }
    }

    public openCommitDialog() {
      if (this.inEvals && !this.editedFormula?.createdAt) {
        this.editedFormula!.id = slug(this.editedFormula!.title, '_')
      }

      this.commitDialogRef!.open(
        this.editedFormula!.id,
        `${this.formulasPath}/${this.editedFormula!.id}`,
        this.editedFormula,
        this.originalFormula,
      )
    }

    public openReviewDialog() {
      this.historyDialogRef!.open(
        `${this.formulasPath}/${this.originalFormula!.id}`,
        this.originalFormula!.title,
        this.originalFormula!.releasedAt?.toDate() || this.originalFormula!.createdAt?.toDate(),
      )
    }

    public openHistoryDialog() {
      this.historyDialogRef!.open(`${this.formulasPath}/${this.editedFormula!.id}`, this.editedFormula!.title)
    }
  }

  export default toNative(FormulasView)
</script>
