<template>
  <v-card-text>
    <v-timeline align-top side="end" density="compact">
      <v-timeline-item
        v-for="(message, index) in messages"
        :key="index"
        :icon="message.role === 'user' ? 'mdi-account' : 'mdi-auto-fix'"
        width="100%"
        dot-color="blue"
      >
        <v-textarea
          v-model="message.content"
          :class="message.role === 'user' ? 'ml-6' : 'mr-6'"
          :readonly="true"
          autofocus
          rows="2"
          :auto-grow="true"
        />
      </v-timeline-item>
      <v-timeline-item key="new-message" icon="mdi-account" width="100%" :dot-color="'blue'">
        <v-card elevation="2">
          <v-card-title>
            <div class="text-body-1 bold">Chat with daphne:</div>
          </v-card-title>

          <v-card-text class="mb-n8">
            <v-textarea v-model="prompt" autofocus rows="2" :auto-grow="true" />
          </v-card-text>

          <v-card-actions>
            <div class="caption">WARNING: This feature is still experimental</div>
            <v-spacer />

            <v-btn
              text="Send"
              color="green"
              prepend-icon="mdi-send"
              :loading="isSending || isLoading"
              :disabled="prompt.length === 0"
              @click="getNewCompletion(prompt)"
            />
          </v-card-actions>
        </v-card>
      </v-timeline-item>
    </v-timeline>
  </v-card-text>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { getApp } from 'firebase/app'
  import { doc, getFirestore, setDoc } from 'firebase/firestore'
  import { getFunctions, httpsCallable } from 'firebase/functions'

  import { generateNewMessage } from '#views/messages/utilities'

  import { InsightsStore } from '#stores'

  @Component({})
  class DaphneThread extends Vue {
    @Prop() public insight!: any
    @Prop() public messageOrder!: number | null
    @Prop() public currentMessage!: any | null

    public messages: { role: 'user' | 'assistant'; content: string | null }[] = []

    public threadId = ''

    public prompt = ''

    public isSending = false
    public isLoading = true

    private insightsStore = new InsightsStore()

    // response.data.foo
    public async fetchMessages(threadId: string | undefined) {
      const functions = getFunctions(getApp(), 'europe-west1')

      const response: any = await httpsCallable(functions, 'startNewChatThreadWithDaphne', { timeout: 100000 })({
        threadId,
      })

      const newThreadId = response.data.threadId

      if (!threadId) {
        await setDoc(doc(getFirestore(), `insights/${this.insight.id}/threads/daphnethread`), { threadId: newThreadId })
      }
      this.threadId = newThreadId
      this.messages = response.data.messages

      this.isLoading = false
    }

    public async getNewCompletion(completionText: string) {
      this.isSending = true

      const functions = getFunctions(getApp(), 'europe-west1')

      const response: any = await httpsCallable(functions, 'sendIntoChatThreadWithDaphne', { timeout: 100000 })({
        prompt: completionText,
        threadId: this.threadId,
        currentMessage: this.currentMessage,
      })

      if (response.data.messages) {
        this.prompt = ''
        this.messages = this.messages.concat(response.data.messages)

        this.isSending = false

        if (response.data.newMessage) {
          const newMessageId = await generateNewMessage(
            response.data.newMessage.title,
            response.data.newMessage.body,
            this.insight.id,
            response.data.newMessage.assistantId,
            this.messageOrder ?? 0,
          )

          this.$router.push(`/${this.$route.path.split('/')[1]}/${this.insight.id}/messages/${newMessageId}`)
        }
      }
    }

    public async created() {
      const threadId = (await this.insightsStore.getDaphneThread(this.insight.id)).data()
      this.fetchMessages(threadId?.threadId)
    }
  }

  export default toNative(DaphneThread)
</script>
