import { v4 as uuid } from 'uuid'

import { Formula } from '#types'

export function createDefaultMode() {
  return {
    name: '',
    condition: 'false',
    day: [],
    morning: [],
    evening: [],
    overnight: [],
  }
}

export function createDefaultFormula(project?: string): Formula {
  return {
    id: uuid(),

    title: '',
    notes: '',

    category: '',
    condition: '',
    description: '',

    project: project || '',
  }
}
