export const insightFormulaCategories: any = [
  {
    title: 'Resting and sleep',
    value: 'resting_sleep',
    color: 'blue',
  },
  {
    title: 'Readiness states',
    value: 'readiness_states',
    color: 'green',
  },
  {
    title: 'Activity level',
    value: 'activity_level',
    color: 'yellow',
  },
  {
    title: 'Body temperature',
    value: 'body_temperature',
    color: 'red',
  },
  {
    title: 'Metabolic',
    value: 'metabolic',
    color: 'orange',
  },
  {
    title: 'Napping times',
    value: 'napping_times',
    color: 'cyan',
  },
  {
    title: 'Bedtime and wake-up',
    value: 'bedtime_wakeup',
    color: 'lime',
  },
  {
    title: 'Stress and resilience',
    value: 'stress_resilience',
    color: 'teal',
  },
  {
    title: 'Workouts and AAD',
    value: 'workouts_aad',
    color: 'amber',
  },
  {
    title: 'Reproductive health',
    value: 'reproductive_health',
    color: 'pink',
  },
  {
    title: 'Tagging and notes',
    value: 'tagging_notes',
    color: 'indigo',
  },
  {
    title: 'Status and goals',
    value: 'status_goals',
    color: 'purple',
  },
  {
    title: 'Other and drafts',
    value: 'other',
    color: 'brown',
  },
]

export const spotlightFormulaCategories: any = [
  {
    title: 'Sleep',
    value: 'sleep',
    color: 'blue',
  },
  {
    title: 'Bedtime',
    value: 'bedtime',
    color: 'blue',
  },
  {
    title: 'Sleep stories',
    value: 'sleep_stories',
    color: 'blue',
  },

  {
    title: 'Readiness',
    value: 'readiness',
    color: 'green',
  },
  {
    title: 'Resilience',
    value: 'resilience',
    color: 'green',
  },

  {
    title: 'Activity goal',
    value: 'activity_goal',
    color: 'yellow',
  },
  {
    title: 'Cardiovascular age',
    value: 'cardiovascular_age',
    color: 'yellow',
  },

  {
    title: 'Meals',
    value: 'meals',
    color: 'indigo',
  },
  {
    title: 'Glucose',
    value: 'glucose',
    color: 'indigo',
  },
  {
    title: 'Hydration',
    value: 'hydration',
    color: 'indigo',
  },

  {
    title: 'Pregnancy',
    value: 'pregnancy',
    color: 'red',
  },
  {
    title: 'Cycle phases',
    value: 'cycle_phases',
    color: 'red',
  },

  {
    title: 'Chronotype',
    value: 'chronotype',
    color: 'orange',
  },
  {
    title: 'Body clock',
    value: 'body_clock',
    color: 'orange',
  },

  {
    title: 'Symptom Radar',
    value: 'symptom_radar',
    color: 'amber',
  },

  {
    title: 'Daytime hr',
    value: 'daytime_hr',
    color: 'pink',
  },
  {
    title: 'Daytime stress',
    value: 'daytime_stress',
    color: 'pink',
  },
  {
    title: 'Daytime stress evening',
    value: 'daytime_stress_evening',
    color: 'pink',
  },

  {
    title: 'Anniversary',
    value: 'anniversary',
    color: 'teal',
  },
  {
    title: 'Quarterly review',
    value: 'quarterly_review',
    color: 'teal',
  },
  {
    title: 'Year in review',
    value: 'year_in_review',
    color: 'teal',
  },

  {
    title: 'Advisor',
    value: 'advisor',
    color: 'lime',
  },
  {
    title: 'Circles',
    value: 'circles',
    color: 'lime',
  },

  {
    title: 'Journal',
    value: 'journal',
    color: 'cyan',
  },
  {
    title: 'Smart tagging',
    value: 'smart_tagging',
    color: 'cyan',
  },

  {
    title: 'Trends',
    value: 'trends',
    color: 'purple',
  },

  {
    title: 'Other and drafts',
    value: 'other',
    color: 'brown',
  },
]

export const spotlightFormulaSubCategories: any = [
  {
    title: 'Intro',
    value: 'intro',
  },
  {
    title: 'Unlocked',
    value: 'unlocked',
  },
  {
    title: 'Stressed',
    value: 'stressed',
  },
  {
    title: 'Resting HR',
    value: 'resting_hr',
  },
  {
    title: 'Restorative',
    value: 'restorative',
  },
  {
    title: 'Ping pong',
    value: 'ping_pong',
  },
  {
    title: 'Typical range',
    value: 'typical_range',
  },
  {
    title: 'Body temperature',
    value: 'body_temp',
  },
  {
    title: 'Placeholder',
    value: 'placeholder',
  },
]
