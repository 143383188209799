<template>
  <v-timeline-item :dot-color="stepColor(nextStep, isComplete, ReleaseStepStates.ROLLOUT)">
    <h2 class="d-flex flex-start">Review process</h2>

    <v-row>
      <v-col class="d-flex align-center">
        <p>
          <template v-if="selectedBuild.platform === 'ios'">
            Public release rollout may be started from App Store
          </template>

          <template v-if="selectedBuild.platform === 'android'">
            Staged rollout in progress. Current rollout percentage: {{ selectedBuild.rolloutPercentage }}%
          </template>
        </p>

        <v-spacer />

        <v-tooltip>
          <template #activator="{ props }">
            <v-chip
              v-bind="props"
              link
              variant="text"
              target="_blank"
              rel="noopener noreferrer"
              href="https://appstoreconnect.apple.com/apps/1043837948/distribution/ios/version/inflight"
            >
              <v-divider />

              <v-icon>mdi-open-in-new</v-icon>
            </v-chip>
          </template>

          <span>Open App Store Connect</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <template v-if="isActive">
      <template
        v-if="
          selectedBuild.state === ReleaseStates.PREPARE_FOR_SUBMISSION && (selectedBuild?.rolloutPercentage ?? 0) <= 0
        "
      >
        <p>Build is ready to be submitted for review</p>

        <v-btn
          rounded
          class="mt-4"
          color="primary"
          variant="outlined"
          text="Submit build for review"
          append-icon="mdi-arrow-right"
          :loading="submittingForReview"
          :disabled="!isAppReleaseAdmin"
          @click="submitForReview(selectedBuild)"
        />

        <v-alert v-if="submissionError" type="error" :text="submissionError" />
      </template>

      <template v-else-if="selectedBuild.state === ReleaseStates.WAITING_FOR_REVIEW">
        <p>
          <v-icon color="info">mdi-clock-time-four-outline</v-icon>

          Waiting for review>
        </p>
      </template>

      <template v-else-if="selectedBuild.state === ReleaseStates.PENDING_FOR_RELEASE">
        <p>
          <v-icon color="success">mdi-check-circle</v-icon>

          <template v-if="selectedBuild.platform === 'ios'">
            Public release rollout may be started from App Store

            <v-chip
              link
              variant="text"
              target="_blank"
              rel="noopener noreferrer"
              href="https://appstoreconnect.apple.com/apps/1043837948/distribution/ios/version/inflight"
            >
              <v-icon>mdi-open-in-new</v-icon>
            </v-chip>
          </template>

          <template v-if="selectedBuild.platform === 'android'">
            Staged rollout in progress. Current rollout percentage: {{ selectedBuild.rolloutPercentage }}%
          </template>
        </p>
      </template>

      <template v-else-if="selectedBuild.state === ReleaseStates.CURRENT_PUBLIC_RELEASE">
        <p>
          <v-icon color="success">mdi-check-circle</v-icon>

          Full public rollout in progress
        </p>
      </template>
    </template>
  </v-timeline-item>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { changeReviewStatus, stepColor } from '#views/releases/utilities'

  import { AppStore } from '#stores'

  import { AppBuild, ReleaseStates, ReleaseStepStates } from '#types'

  @Component({})
  class StepReleaseState extends Vue {
    @Prop({ required: true }) public nextStep!: number

    @Prop({ default: false }) public isComplete!: boolean

    @Prop({ required: true }) public selectedBuild!: AppBuild

    public submissionError = ''
    public submittingForReview = false

    public readonly stepColor = stepColor

    public readonly ReleaseStates = ReleaseStates

    public readonly ReleaseStepStates = ReleaseStepStates

    private readonly appStore = new AppStore()

    public get isActive() {
      return this.nextStep === ReleaseStepStates.ROLLOUT
    }

    public get isAppReleaseAdmin() {
      return this.appStore.isAppReleaseAdmin
    }

    public async submitForReview(build: AppBuild) {
      const { version, versionId, buildVersion, buildId, platform } = build

      if (!version || !buildVersion || !platform || !buildId) {
        return
      }

      const confirm = await this.$confirm(
        'Submit build for review?',
        `Platform: ${platform === 'ios' ? 'iOS' : 'Android'} | Version: ${version} | Build: ${buildVersion}`,
      )

      if (confirm) {
        this.submittingForReview = true

        await changeReviewStatus('submit', {
          buildVersion,
          version,
          versionId,
          platform,
        })
          .catch((error) => {
            this.submissionError = `Review submission failed, try again. (${error})`
          })
          .finally(() => {
            this.submittingForReview = false
          })
      }
    }
  }

  export default toNative(StepReleaseState)
</script>
